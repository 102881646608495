<template>
  <div>
    <div class="d-flex justify-end" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
      <div style="width: 35%"></div>
      <v-select class="pa-2" outlined filled dense label="Liquidation" :value="selected_liquidation"
        v-model="selected_liquidation" :items="[
          {
            'value': null,
            'text': 'All'
          },
          {
            'value': 'liquidating',
            'text': 'Liquidating'
          },

        ]" @input="(dateRange) => liquidationChanged(dateRange)"></v-select>


      <v-select class="pa-2" outlined filled dense label="Sales Post" :value="selected_driver" v-model="selected_driver"
        :items="getDrivers" @input="(dateRange) => salesPostChanged(dateRange)"></v-select>
      <v-select class="pa-2" outlined filled dense v-model="dateRange" @input="(dateRange) => dateRangeChanged(dateRange)"
        :items="[
          'This Month',
          'Last Month',
          'Today',
          'Yesterday',
          'This Year',
          'Last Year',
          'Custom Date',
        ]"></v-select>
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date"
        transition="scale-transition" offset-y min-width="100px" width="200px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="date" label="Custom Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
            v-on="on"></v-text-field>
        </template>
        <v-date-picker range v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="dateRangeChanged(date, 'customDate')">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-card>
      <v-card-title>
        {{ translate("Orders") }}
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
      </v-card-title>

      <v-data-table sort-by.sync="order_status" sort-desc.sync="true" :search="search" :items-per-page="20"
        :headers="headers" :items="computedOrders" class="elevation-1">
        <template v-slot:footer.page-text>
          <v-row style="width: 300px">
            <v-col cols="5">
              <h4>Orders: {{ totalOrders }}</h4>
            </v-col><v-col>
              <h4>Total Price: {{ sum }} Lek</h4>
            </v-col></v-row>
        </template>
        <template v-slot:item.order_status="{ item }">
          <span class="font-weight-medium v-chip v-size--small" :class="item.color"><span class="v-chip__content">
              {{ orderStatusToText(item.order_status) }}
            </span></span>
        </template>
        <template v-slot:item.deadline="{ item }">
          <span>{{ formatDate(item.deadline) }}</span>&nbsp;
          <span class="font-weight-medium v-chip v-size--small primary"><span class="v-chip__content"> {{
            timeAgo(item.deadline) }} </span></span>
        </template>
        <template v-slot:item.id="{ item }">
          <v-dialog width="60%">
            <template v-slot:activator="{ on, attrs }">
              <a color="red lighten-2" dark v-bind="attrs" v-on="on">
                {{ item.id }}
              </a>
            </template>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">{{ translate("Order") }} #{{ item.id }}</v-card-title>
              <v-card-text>
                <OrderInfo @setNewData="setNewData" :currency="currency" :order="item"></OrderInfo>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
        </template>
        <template v-slot:item.actions="{ item }">
          <router-link :to="{ name: 'edit_order', query: { id: item.id } }">
            <v-icon style="color: var(--v-warning-base)" small> mdi-pencil </v-icon>
          </router-link>
          <v-dialog :retain-focus="false" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click.stop="item.delete_dialog = true" small style="color: var(--v-error-base)" v-bind="attrs"
                v-on="on">
                mdi-delete
              </v-icon>
            </template>

            <v-card>
              <v-card-title class="text-h5">{{ translate("Are you sure want to delete Order") }}
                {{ item.id }}?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="delete_dialog = false">{{
                  translate("Cancel")
                }}</v-btn>
                <v-btn color="primary" @click="handleDelete(item.id)">{{
                  translate("OK")
                }}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
    </v-card>
    <div class="text-center">
      <downloadExcel class="elevation-0 v-btn v-size--small v-btn--outlined v-btn--tile" :data="orders" :fields="{
        order_id: 'id',
        order_status: 'order_status',
        order_created_at: 'order_created_at',
        deadline: 'deadline',
        order_completed_at: 'order_completed_at',
        magazine: 'magazine_name',
        created_by_user_id: 'created_by_user_id',
        driver_id: 'driver_id',
        delivery_fee: 'delivery_fee',
        driver_name: 'driver_name',
        driver_phone_number: 'driver_phone_number',
        client_name: 'client_name',
        client_phone_number: 'client_phone',
        client_address: 'client_address',
        client_lat: 'client_lat',
        client_lng: 'client_lng',
        order_status: 'order_status_formatted',
        reason: 'refuse_reason',
        origin: 'origin_type',
        total_price: 'total_price',
        profit: 'profit',
      }">
          Export Excel   
      </downloadExcel>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "@vue/composition-api";
import OrderInfo from "@/views/pages/orders/OrderInfo.vue";
import {
  deleteOrder,
  getApprovedDrivers,
  getCompletedOrders,
  getCurrency,
  getMagazines,
  getOrders,
} from "@/ApiManager";
import moment from "moment";

export default {
  computed: {
    totalOrders() {
      return this.orders.length;
    },
    sum() {
      var a = 0;
      for (var i = 0; i < this.orders.length; i++) {
        a += parseInt(this.orders[i].total_price);
      }
      return a;
    },
    getOrderStatuses() {
      var orderStatuses = [
        "choose_agents",
        "designing",
        "production",
        "waiting_for_a_driver",
        "driver_picking_up",
        "company_delivering",
        "client_picking_up",
        "liquidating",
        "driver_liquidating",
        "returned",
      ];
      var result = [];
      for (var i = 0; i < orderStatuses.length; i++) {
        result.push({
          value: orderStatuses[i],
          text: this.orderStatusToText(orderStatuses[i]),
        });
      }
      return result;
    },
    getDrivers() {
      var drivers = [];
      drivers.push({ value: "client_picks_up", text: "Shop Peshkopi" });
      drivers.push({ value: "delivery_company", text: "Addex" });
      for (var i = 0; i < this.drivers.length; i++) {
        if (this.drivers[i].role == "driver")
          drivers.push({ value: this.drivers[i].id, text: this.drivers[i].name });
      }
      drivers.push({ value: "null", text: "All" });

      return drivers;
    },
    computedOrders() {
      return this.orders;
    },
  },

  data() {
    return {
      selected_liquidation: null,
      dateRange: "This Month",
      date: null,
      menu: null,
      filteredOrders: [],
      selectedDate: null,
      filterDialog: false,
      search: "",
      selected_order_status: null,
      delete_dialog: false,
      selected_driver: null,
      currency: "",
      magazine_names: [],
      single_name: [],
      drivers: [],
      orders: [],
      headers: [
        {
          align: "start",
          value: "name",
        },
        { text: this.translate("Order Number"), value: "id" },
        { text: this.translate("Deadline"), value: "deadline" },
        { text: this.translate("Customer Name"), value: "client_name" },
        { text: this.translate("Magazine"), value: "magazine_name" },
        { text: this.translate("Order Status"), value: "order_status" },
        { text: this.translate("Total Price"), value: "total_price" },
        { text: this.translate("Actions"), value: "actions" },
      ],
    };
  },
  components: {
    OrderInfo,
  },
  methods: {
    liquidationChanged() {
      this.$store.state.loading = true;
      this.getOrdersLocal();
    },
    salesPostChanged() {
      this.$store.state.loading = true;
      this.getOrdersLocal();
    },
    dateRangeChanged(dateRange, customDate) {
      if (dateRange == "Custom Date" && customDate == undefined) return;
      this.$store.state.loading = true;
      console.log(customDate);
      if (customDate != undefined) {
        this.dateRange = "Custom Date";
      }
      this.getOrdersLocal();
    },
    setNewData() {
      this.getOrdersLocal();
    },
    getOrdersLocal() {
      getOrders(this.selected_driver, this.dateRange, this.selected_liquidation).then((response) => {
        this.orders = response.data;
        this.orders.forEach((product) => {
          product.total_price = `${product.total_price} ${this.currency}`;
          product.color = this.orderStatusToColor(product.order_status);
          product.order_status_formatted = this.orderStatusToText(product.order_status);
        });
        this.filteredOrders = this.orders;
        this.$store.state.loading = false;
      });
    },

    sameDay(d1, d2) {
      return (
        d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate()
      );
    },
    magazine_selected(magazine_name) {
      for (var i = 0; i < this.magazine_names.length; i++)
        if (this.magazine_names[i].name == magazine_name)
          this.edit_magazine = this.magazine_names[i].id;
    },
    async handleDelete(id) {
      this.$store.state.loading = true;
      this.delete_dialog = false;
      await deleteOrder(id);
      console.log(id);
      this.orders = this.orders.filter(function (item) {
        return item.id != id;
      });
      this.$store.state.loading = false;
    },
  },
  created() {
    this.$store.state.loading = true;
    //this.$store.state.socketio.on('orders_updated', (...args) => {
    //  this.getOrdersLocal()
    //})
    this.getOrdersLocal();

    getCurrency().then((response) => {
      this.currency = response["currency"];
    });

    getMagazines().then((response) => {
      this.magazine_names = response;
    });
    getApprovedDrivers().then((response) => {
      this.drivers = response;
    });
  },
};
</script>

<style>
a {
  text-decoration: none;
}
</style>
